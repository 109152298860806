export default {
	namespaced: true,

	actions: {
		fetchAll({ rootState }, { date }) {
			return window.axios
				.get(
					`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/monthly-notes`,
					{ params: { month: date } },
				)
				.then((res) => {
					return res.data;
				});
		},
	},
};
