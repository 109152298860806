import * as types from '../mutations';

export default {
	namespaced: true,

	state: {
		attendance: {},
	},

	actions: {
		fetchAll({ rootState, commit, state }) {
			return Object.keys(state.attendance).length
				? state.attendance
				: window.axios
					.get(
						`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/student-attends`,
					)
					.then((res) => {
						commit(types.ATTENDANCE, res.data);
						return res.data;
					});
		},
		fetchRate({ rootState }) {
			return window.axios
				.get(
					`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/attendance-rate`,
				)
				.then((res) => {
					return res.data;
				});
		},
		clear({ commit }) {
			commit('CLEAR');
		},
	},

	mutations: {
		[types.ATTENDANCE](state, attendance) {
			state.attendance = attendance;
		},
		CLEAR(state) {
			state.attendance = {};
		},
	},
};
