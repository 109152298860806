import * as types from '../mutations'
export default {
	namespaced: true,

	state: {
		notifications: []
	},

	actions: {
		fetchAll({ rootState, commit }) {
			return window.axios.get(`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/notifications`).then(({ data }) => {
				commit(types.STORE_NOTIFICATIONS, data);
			})
		},

		clear({ commit }) {
			commit('CLEAR');
		}
	},

	mutations: {
		[types.STORE_NOTIFICATIONS](state, notifications) {
			state.notifications = notifications;
		},

		CLEAR(state) {
			state.notifications = [];
		}
	},

	getters: {
		getNotificationById: state => id => state.notifications.find(c => c.Id === id)
	}
}