import * as types from '../mutations';

export default {
	namespaced: true,

	state: {
		exams: [],
		examSchedule: [],
		lastExam: null,
	},

	actions: {
		fetchAll({ commit, rootState, state }) {
			return state.exams.length
				? state.exams
				: window.axios
					.get(
						`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/tests`,
					)
					.then((res) => {
						commit(types.EXAMS, res.data);
						return res.data;
					});
		},
		fetchLast({ rootState, commit, state }) {
			return (
				state.lastExam ||
				window.axios
					.get(
						`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/last-test`,
					)
					.then((res) => {
						commit(types.LAST_EXAM, res.data);
						return res.data;
					})
			);
		},
		fetchSchedule({ rootState, commit, state }) {
			return state.examSchedule.length
				? state.examSchedule
				: window.axios
					.get(
						`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/test-schedule`,
					)
					.then((res) => {
						commit(types.EXAMS_SCHEDULE, res.data);
						return res.data;
					});
		},
		clear({ commit }) {
			commit('CLEAR');
		},
	},
	mutations: {
		[types.EXAMS](state, exams) {
			state.exams = exams;
		},
		[types.EXAMS_SCHEDULE](state, examSchedule) {
			state.examSchedule = examSchedule || [];
		},
		[types.LAST_EXAM](state, lastExam) {
			state.lastExam = lastExam;
		},
		CLEAR(state) {
			state.exams = [];
			state.examSchedule = [];
			state.lastExam = null;
		},
	},
};
