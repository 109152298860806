import { getDeviceToken } from '@/firebase/messaging';

// get and save notification token
let notificationToken = localStorage.getItem('n-t');
getDeviceToken().then(token => {
	if (token) {
		notificationToken = token;
		localStorage.setItem('n-t', token);
	}
})

// add token value on 'login' and 'logout' requests
window.axios.interceptors.request.use(
	(configs) => {
		if (notificationToken && (configs.url.includes('/login') || configs.url.includes('/log-out'))) {
			configs.params = {
				...configs.params,
				token: notificationToken
			}
		}
		return configs
	},
	(error) => {
		return Promise.reject(error)
	}
);

// add tokens to already logged-in users
if (notificationToken) {
	const promises = [];
	const tempUsers = [];
	const ntUsers = JSON.parse(localStorage.getItem('nt-us')) || [];
	
	localStorage.setItem('nt-us', JSON.stringify([]));

	const authoredUsers = JSON.parse(localStorage.getItem('auth'));
	if (Array.isArray(authoredUsers)) {
		authoredUsers.forEach(item => {
			const matchedUser = ntUsers.find(c => c.u === item.userName);
			if (!matchedUser) {
				promises.push(
					window.axios.post(`/students/${item.userName}/login`, null, {
						headers: {
							'X-Password': item.password,
						},
					}).then(() => {
						tempUsers.push({ u: item.userName, t: true });
					})
				)
			} else {
				tempUsers.push({ u: item.userName, t: true });
			}
		})
	}
	Promise.all(promises).then(() => {
		if (tempUsers.length) {
			localStorage.setItem('nt-us', JSON.stringify(tempUsers));
		}
	})
}