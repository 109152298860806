import * as types from '../mutations'

export default {
	namespaced: true,
	
	state: {
		homeworks: [],
		nextHomeworks: []
	},

	actions: {
		getHomeworks({ rootState, commit }, { fromDate, toDate }) {
			return window.axios
				.get(
					`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/get-homeworks`, { 
						params: { fromDate, toDate } 
					}
				).then((res) => {
					commit(types.STORE_HOMEWORKS, res.data);
					return res.data;
				});
		},
		getNextHomeworks({ commit, rootState }, { fromDate, toDate }) {
			return window.axios
				.get(
					`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/get-next-homeworks`, { 
						params: { fromDate, toDate } 
					}
				).then((res) => {
					commit(types.STORE_NEXT_HOMEWORKS, res.data);
					return res.data;
				});
		}
	},

	mutations: {
		[types.STORE_HOMEWORKS](state, homeworks) {
			state.homeworks = homeworks;
		},
		[types.STORE_NEXT_HOMEWORKS](state, nextHomeworks) {
			state.nextHomeworks = nextHomeworks
		}
	}
}