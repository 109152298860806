<template>
	<div class="page">
		<apexchart
			class="mb-4"
			type="donut"
			height="250"
			:options="options"
			:series="attendanceArr">
		</apexchart>

		<!-- attend selecte -->
		<v-row class="mb-4">
			<!-- absence -->
			<v-col cols="4">
				<v-card
					@click="typeIndex = typesEnum.absence"
					:class="{
						'homeOrange white--text':
							typeIndex === typesEnum.absence,
					}"
					class="px-1 py-4"
					rounded="lg"
					elevation="3">
					<div class="text-center">
						<span class="text-h5">
							{{ attendance.TotalAbsence }}
						</span>
						<br />
						<span>غياب</span>
					</div>
				</v-card>
			</v-col>

			<!-- late -->
			<v-col cols="4">
				<v-card
					@click="typeIndex = typesEnum.late"
					:class="{
						'myOrange white--text': typeIndex === typesEnum.late,
					}"
					class="px-1 py-4"
					rounded="lg"
					elevation="3">
					<div class="text-center">
						<span class="text-h5">
							{{ attendance.TotalLate }}
						</span>
						<br />
						<span>تأخير</span>
					</div>
				</v-card>
			</v-col>

			<!-- earlyLeave -->
			<v-col cols="4">
				<v-card
					@click="typeIndex = typesEnum.earlyLeave"
					:class="{
						'homeBlue white--text':
							typeIndex === typesEnum.earlyLeave,
					}"
					class="px-1 py-4"
					rounded="lg"
					elevation="3">
					<div class="text-center">
						<span class="text-h5">
							{{ attendance.TotalEarlyLeave }}
						</span>
						<br />
						<span>إنصراف مبكر</span>
					</div>
				</v-card>
			</v-col>
		</v-row>

		<!-- attend list -->
		<div v-if="attendanceType?.length">
			<div class="text-h5 mb-3">التفاصيل</div>
			<v-card
				rounded="lg"
				elevation="3"
				class="overflow-hidden mb-2 right-border py-2"
				v-for="item in attendanceType"
				:key="item.id"
				:style="`--right-border: ${
					typeIndex === typesEnum.absence
						? '#fb5d32'
						: typeIndex === typesEnum.late
						? '#ee8c2d'
						: '#07a0c2'
				}`">
				<div class="d-flex">
					<!-- date -->
					<div class="date text-center py-2">
						<span>{{ new Date(item.Date).getDate() }}</span>
						<br />
						<span>{{ getMonth(new Date(item.Date)) }}</span>
						<br />
						<span>{{ new Date(item.Date).getFullYear() }}</span>
					</div>

					<v-divider vertical></v-divider>

					<!-- info -->
					<div class="flex-grow-1 py-4 px-3">
						<div>المبرر: {{ item.ParentJustification }}</div>
						<div>السبب: {{ item.Justification }}</div>
					</div>
				</div>
			</v-card>
		</div>
		<div
			v-else
			class="text-center text--secondary py-8">
			لا توجد حالات
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { getMonth } from '@/helpers/helpers';

export default {
	name: 'AttendanceView',

	data() {
		return {
			types: ['AbsenceList', 'LateList', 'EarlyLeaveList'],
			typeIndex: 0,
			typesEnum: {
				absence: 0,
				late: 1,
				earlyLeave: 2,
			},

			rate: null,

			options: {
				plotOptions: {
					pie: {
						donut: {
							size: '40%',
						},
					},
				},
				colors: ['#b71c1c', '#259952'],
				labels: ['غياب', 'حضور'],
				chart: {
					toolbar: {
						show: false,
					},
				},
				dataLabels: {
					style: {
						colors: ['#fff'],
					},
					formatter: function (x, obj, y) {
						return (
							obj.w.config.series[obj.seriesIndex] +
							' ' +
							obj.w.config.labels[obj.seriesIndex]
						);
					},
					dropShadow: {
						enabled: false,
					},
				},
				legend: {
					show: false,
				},
				tooltip: {
					enabled: false,
				},
			},

			attendanceArr: [],
		};
	},

	computed: {
		attendanceType() {
			return this.attendance[this.types[this.typeIndex]];
		},
		...mapState({
			attendance: (state) => state.attendance.attendance,
		}),
	},

	methods: {
		getMonth,
		fetch() {
			this.$eventBus.$emit('page-loader', true);
			Promise.all([
				this.$store.dispatch('attendance/fetchAll'),
				this.$store.dispatch('attendance/fetchRate').then((res) => {
					this.rate = res;
					this.attendanceArr = [
						this.rate.TotalRate - this.rate.StudentRate,
						this.rate.StudentRate,
					];
				}),
			]).finally(() => {
				this.$eventBus.$emit('page-loader', false);
			});
		},
	},

	mounted() {
		this.fetch();
	},
};
</script>

<style scoped lang="scss">
.v-card {
	overflow-wrap: normal;
}
.date {
	max-width: 80px;
	min-width: 80px;
}
.right-border {
	position: relative;
	overflow: hidden;
	padding-right: 10px !important;
	&::before {
		opacity: 1;
		content: '';
		height: 100%;
		width: 7px;
		background-color: var(--right-border);
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
	}
}
</style>
