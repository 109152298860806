<template>
	<div class="loader d-flex flex-column align-center justify-center">
		<lottie-vue-player
			class="loader"
			:src="'/loader.json'"
			:loop="true"
			:autoplay="true"
			:backgroundColor="'transparent'"
			style="width: 150px"></lottie-vue-player>
		<span>powered by Edunix</span>
	</div>
</template>

<script>
export default {
	name: 'Loader',
};
</script>

<style lang="scss">
.loader {
	background-color: transparent !important;
	* {
		stroke: var(--v-secondary-base);
	}
}
</style>
