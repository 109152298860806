export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const HOME_ATTENDANCE = 'HOME_ATTENDANCE';
export const HOME_STATISTICS = 'HOME_STATISTICS';
export const HOME_LAST_MARK = 'HOME_LAST_MARK';
export const HOME_VERSION = 'HOME_VERSION';

export const EXAMS = 'EXAMS';
export const EXAMS_SCHEDULE = 'EXAMS_SCHEDULE';
export const LAST_EXAM = 'LAST_EXAM';

export const BEHAVIOR = 'BEHAVIOR';

export const MESSAGES = 'MESSAGES';

export const MARKS = 'MARKS';
export const SUBJECT_AVERAGE = 'SUBJECT_AVERAGE';

export const ATTENDANCE = 'ATTENDANCE';

export const STATISTICS = 'STATISTICS';

export const STORE_NOTIFICATIONS = 'STORE_NOTIFICATIONS';

export const STORE_HOMEWORKS = 'STORE_HOMEWORKS'
export const STORE_NEXT_HOMEWORKS = 'STORE_NEXT_HOMEWORKS'
