<template>
	<div
		v-if="$isOffline.value"
		class="text-h4 text--secondary text-center d-flex justify-center align-center"
		style="height: 50vh">
		لا يوجد الاتصال بالانترنت
	</div>
	<div
		v-else
		class="page">
		<!-- date pick -->
		<div class="d-flex align-center justify-space-around">
			<v-btn
				x-small
				fab
				class="secondary"
				elevation="0"
				@click="nextMonth">
				<v-icon>mdi-arrow-right</v-icon>
			</v-btn>
			<div
				@click="showDialog = true"
				class="primary pa-3 rounded-lg white--text text-center text-h6"
				style="width: 220px">
				خلال شهر <br />
				{{ thisMonth }}
				{{
					new Date(date).toLocaleString('en-US', {
						year: 'numeric',
					})
				}}
			</div>
			<v-btn
				x-small
				fab
				class="secondary"
				elevation="0"
				@click="prevMonth">
				<v-icon> mdi-arrow-left </v-icon>
			</v-btn>
		</div>

		<!-- main content -->
		<v-list
			v-if="behaviorsNotes.length"
			dense
			color="transparent">
			<v-list-item
				class="white my-2 py-2 px-3 rounded-md"
				v-for="(note, i) in behaviorsNotes"
				:key="i">
				<div class="flex-grow-1">
					<div class="text-body-1">
						{{ note.Content }}
					</div>
					<v-divider class="my-1"></v-divider>
					<div class="text-caption text-muted">
						من:
						{{
							note.TeacherId
								? note.TeacherName
								: note.SupervisorName
						}}
					</div>
					<div class="text-caption text-muted">
						تاريخ: {{ dateFormater(new Date(note.Date)) }}
					</div>
				</div>
			</v-list-item>
		</v-list>
		<div
			v-else
			class="text-h5 text--secondary text-center py-10">
			لا توجد ملاحظات لهذا الشهر
		</div>

		<v-dialog
			v-model="showDialog"
			width="290px">
			<v-date-picker
				elevation="3"
				@change="changeDate"
				scrollable
				locale="ar-SY"
				type="month"></v-date-picker>
		</v-dialog>
	</div>
</template>

<script>
import { dateFormater, getMonth } from '@/helpers/helpers.js';

export default {
	name: 'BehaviorView',

	data() {
		return {
			date: new Date(),

			behaviorsNotes: [],

			showDialog: false,
		};
	},

	computed: {
		thisMonth() {
			return this.getMonth(this.date);
		},
	},

	methods: {
		dateFormater,
		getMonth,
		changeDate(event) {
			this.date = new Date(event);
			this.fetch();
			this.showDialog = false;
		},
		nextMonth() {
			this.date = new Date(this.date.setMonth(this.date.getMonth() + 1));
			this.fetch();
		},
		prevMonth() {
			this.date = new Date(this.date.setMonth(this.date.getMonth() - 1));
			this.fetch();
		},
		fetch() {
			this.$eventBus.$emit('page-loader', true);
			this.$store
				.dispatch('behavior/fetchAll', {
					// api accept to get in sec
					date: Math.floor(this.date.getTime()),
				})
				.then((res) => {
					this.behaviorsNotes = res;
				})
				.finally(() => {
					this.$eventBus.$emit('page-loader', false);
				});
		},
	},

	mounted() {
		this.fetch();
	},
};
</script>
