<template>
	<div
		v-if="$isOffline.value"
		class="text-h4 text--secondary text-center d-flex justify-center align-center"
		style="height: 100vh">
		لا يوجد الاتصال بالانترنت
	</div>
	<v-container
		v-else
		class="fill-height header-image"
		fluid>
		<v-sheet
			class="transparent mx-auto"
			width="100%">
			<v-img
				class="mx-auto mb-7"
				src="../assets/Logo.png"
				height="8rem"
				max-width="8rem"
				contain></v-img>
			<v-card
				class="pa-4 transparent"
				elevation="0">
				<h1 class="primary--text text-center text-h4 mb-4">حساباتك</h1>
				<v-list
					class="transparent mb-5"
					v-if="users.length">
					<v-list-item
						v-for="(user, i) in users"
						:key="i">
						<v-list-item-content @click="login(user)">
							<v-list-item-title>
								{{ user.userName }}
							</v-list-item-title>
						</v-list-item-content>
						<v-list-item-icon
							@click="
								openLogoutDialog = true;
								userToLogout = user;
							">
							<v-icon color="myOrange"> mdi-logout </v-icon>
						</v-list-item-icon>
					</v-list-item>
				</v-list>
				<div
					v-else
					class="text-center mb-10">
					لم يتم التسجيل باي حساب
				</div>
				<div class="text-center">
					<v-btn
						:loading="loading"
						:disabled="loading"
						large
						class="rounded-lg"
						color="secondary"
						:to="{ name: 'login' }">
						اضافة حساب
					</v-btn>
				</div>
			</v-card>
		</v-sheet>

		<v-dialog
			v-model="openLogoutDialog"
			width="400">
			<v-card
				class="pa-6"
				rounded="lg">
				<p class="text-center mb-8 text-h6">هل تريد تسجيل الخروج؟</p>
				<v-row>
					<v-col cols="6">
						<v-btn
							color="primary"
							block
							@click="
								openLogoutDialog = false;
								userToLogout = null;
							">
							إلغاء
						</v-btn>
					</v-col>
					<v-col cols="6">
						<v-btn
							color="primary"
							text
							block
							@click="
								openLogoutDialog = false;
								logout(userToLogout);
							">
							تاكيد
						</v-btn>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'AccountsView',

	data() {
		return {
			loading: false,

			openLogoutDialog: false,
			userToLogout: null,
		};
	},

	methods: {
		login(user) {
			this.loading = true;

			this.$store
				.dispatch('login/login', {
					userName: user.userName,
					password: user.password,
				})
				.then((res) => {
					this.$store.dispatch('clearAll');
					this.$router.push({ name: 'home' });
				})
				.catch((err) => {
					if (err.response.status === 401) {
						this.$store.dispatch('login/logout', { user });
						this.$eventBus.$emit(
							'show-snackbar',
							true,
							'تم تعديل معلومات الحساب او حذفه',
						);
						if (this.users.length) {
							this.$router.replace({ name: 'login' });
						}
					} else {
						this.$eventBus.$emit(
							'show-snackbar',
							true,
							'حدث خطأ ما. اعد المحاولة لاحقا',
						);
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		logout(user) {
			this.$store.dispatch('login/logout', { user });
			this.$eventBus.$emit('show-snackbar', false, 'تم حذف المستخدم');
			if (!this.users.length) this.$router.push({ name: 'login' });

			this.userToLogout = null;
		},
	},
	computed: {
		...mapState({
			users: (state) => state.login.users,
			activeUser: (state) => state.login.activeUser,
		}),
	},
};
</script>
