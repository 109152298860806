<template>
  <div class="homework">
	<!-- dialog -->
	<v-dialog
		v-model="dialog.show"
		max-width="380"
		>
			<v-card
				class="rounded-xl pa-4"
			>
				<v-list-item three-line class="pa-0">
					<v-list-item-avatar
						tile
						size="55"
						color="myBlue"
						class="align-self-center rounded-lg me-2"
					>
						<v-img
						class="align-center rounded-0"
						src="@/assets/homework1.png"
						max-width="35"
						max-height="35"
						>
						</v-img>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title class="title homeBlue--text mb-0">
							{{ dialog.subjectName }}
						</v-list-item-title>

						<v-list-item-subtitle class="text-subtitle-1">
							{{ dialog.teacherName }}
						</v-list-item-subtitle>

						<v-list-item-title class="subtitle-2">
							<strong>وقت التسليم:</strong>
							{{ dialog.expectedDate == null ? 'لايوجد وقت محدد للتسليم' : moment(dialog.expectedDate).format('YYYY-MM-DD') }}
							<span v-if="dialog.expectedDate" class="myOrange--text text-subtitle-2">
								({{Math.abs(moment().diff(dialog.expectedDate, 'days'))}} يوم متبقي)
							</span>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="text-h5 homeBlue--text">
							تفاصيل الواجب:
						</v-list-item-title>
						<v-list-item-subtitle class="title black--text">
							{{ dialog.details }}
						</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>

				<v-row>
					<v-spacer></v-spacer>
					<v-col cols="auto">
						<v-btn
							height="30"
							text
							color="white"
							class="rounded myBlue caption"
							@click="dialog.show = false"
						>
							اغلاق
						</v-btn>
					</v-col>
				</v-row>
			</v-card>
	</v-dialog>

	<v-container fluid>
		<v-tabs
			v-model="tab"
			grow
			class="sticky mt-n2"
			@change="tab === 0 ? getNextHomeworks() : getHomeworks()"
		>
			<v-tab>الواجبات القادمة</v-tab>
			<v-tab>كل الواجبات</v-tab>
		</v-tabs>
		
		<!-- tab-2 -->
		<div v-if="tab === 1" class="mt-11">
			<div class="mb-2 d-flex flex-row align-center justify-space-between ">
				<v-btn
					class="ms-2"
					icon
					@click="month = moment(month).subtract(1, 'month').format('YYYY-MM')"
				>
					<v-icon>mdi-chevron-right</v-icon>
				</v-btn>
				<span class="title myBlue--text">{{moment(month).locale('ar-ly').format('MMMM YYYY')}}</span>
				<v-btn
					class="me-2"
					icon
					@click="month = moment(month).add(1, 'month').format('YYYY-MM')"
				>
					<v-icon>mdi-chevron-left</v-icon>
				</v-btn>
			</div>
			<VMonthDays
				v-model="date"
				:month="month"
				color="white"
				@input="getHomeworks()"
			/>
		</div>

		<div
			v-if="loading"
			class="d-flex justify-center align-center"
			style="height: 60vh"
		>
			<v-progress-circular indeterminate color="primary"/>
		</div>

		<div v-else class="mt-8">
			<div
				v-for="(group, i) in groupedHomeworks"
				:key="i"
			>
				<v-row v-if="tab === 0" dense class="mt-0">
					<template v-if="group.date">
						<v-col cols="4" col="4">
							<v-card-title class="text-title">
								{{ moment(group.date).format('dddd') }}
							</v-card-title>
						</v-col>
						<v-spacer></v-spacer>
						<v-col cols="3">
							<v-card-title class="text-subtitle-1">
								{{ moment(group.date).format('MM/DD') }}
							</v-card-title>
						</v-col>
					</template>
			
					<v-col v-else cols="auto">
						<v-card-title class="text-title">
							لا يوجد وقت محدد للتسليم
						</v-card-title>
					</v-col>
				</v-row>
			
				<v-card
					class="rounded-xl mb-2"
					v-for="homework in group.homeworks"
					:key="homework.id"
				>
					<v-list-item three-line>
						<v-list-item-avatar
							tile
							size="55"
							color="myBlue"
							class="align-self-center rounded-lg me-2"
						>
							<v-img
								class="align-center rounded-0"
								src="@/assets/homework1.png"
								max-width="35"
								max-height="35"
							></v-img>
						</v-list-item-avatar>
			
						<v-list-item-content>
							<v-list-item-title class="mb-0">
								<v-row dense>
									<v-col>
										<span class="homeBlue--text title"> {{ homework.SubjectName }} </span>
									</v-col>
									<v-spacer></v-spacer>
									<v-col>
										<v-btn
											height="30"
											text
											color="white"
											class="rounded myBlue text-caption"
											@click="openDialog(homework)"
										>
											تفاصيل الواجب
										</v-btn>
									</v-col>
								</v-row>
							</v-list-item-title>
			
							<v-list-item-subtitle class="text-subtitle-1">
								{{ homework.TeacherName }}
							</v-list-item-subtitle>
			
							<v-list-item-title
								v-if="homework.ExpectedDate"
								class="text-subtitle-2"
							>
								<strong>وقت التسليم:</strong>
								{{ moment(homework.ExpectedDate).format('YYYY-MM-DD') }}
								<span class="myOrange--text text-subtitle-2">
									{{getDiffText(homework.ExpectedDate)}}
								</span>
			
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-card>
			</div>
			
			<div v-if="!groupedHomeworks.length" class="text-center mt-10">
				لا يوجد واجبات
			</div>
		</div>
	</v-container>
  </div>
</template>

<script>
import VMonthDays from '@/components/VMonthDays.vue'
import moment from '@/moment';
import { mapState } from 'vuex';

export default {
	name: 'HomeworkView',
	components: { VMonthDays },
	
	data () {
		return {
			date: moment().format('YYYY-MM-DD'),
			loading: false,
			moment,
			tab: null,
			dialog: {
				show: false,
				subjectName: '',
				teacherName: '',
				expectedDate: '',
				details: ''
			},
			month: moment().format('YYYY-MM'),
			fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
			toDate: moment().add(7, 'days').format('YYYY-MM-DD'),
		}
	},

	computed: {
		...mapState({
			homeworks: (state) => state.homeworks.homeworks,
			nextHomeworks: (state) => state.homeworks.nextHomeworks
		}),

		groupedHomeworks() {
			return this.buildGroupedHomeworks(
				this.tab === 0 ? this.nextHomeworks : this.homeworks,
				this.tab === 0 ? 'ExpectedDate' : 'DateTime',
			);
		}
	},

	methods: {
		openDialog(homework) {
			this.dialog.show = true
			this.dialog.subjectName = homework.SubjectName
			this.dialog.teacherName = homework.TeacherName
			this.dialog.expectedDate = homework.ExpectedDate
			this.dialog.details = homework.Details
		},

		buildGroupedHomeworks(data, dateProp) {
			const items = [];
			let tempDate = null;
			data.forEach(c => {
				const date = c[dateProp] && moment(c[dateProp]).format('YYYY-MM-DD');
				if (date && moment(date).diff(moment(), 'days') < 0) return;

				if (tempDate !== moment(c[dateProp]).format('YYYY-MM-DD')) {
					tempDate = date;
					items.push({
						date: date,
						homeworks: [c]
					})
				} else {
					const index = items.findIndex(e => e.date === date);
					if (index !== -1) {
						items[index].homeworks.push(c);
					}
				}
			});
			if (items[0] && !items[0].date) {
				items.push(...items.splice(0, 1));
			}
			return items;
		},
		
		getDiffText(date) {
			const diff = moment(date).diff(moment(), 'days');
			if (diff > 0) return `(${moment(date).diff(moment(), 'days')} يوم متبقي)`
			else if (diff === 0) return '(متبقى أقل من يوم)'
			else return '(انتهى موعد التسليم)'
		},

		getHomeworks() {
			this.loading = true
			return this.$store.dispatch('homeworks/getHomeworks', {
				fromDate: this.date,
				toDate: this.date
			}).finally(() => { this.loading = false });
		},
		getNextHomeworks() {
			this.loading = true
			return this.$store.dispatch('homeworks/getNextHomeworks', {
				fromDate: this.fromDate,
				toDate: this.toDate
			}).finally(() => { this.loading = false });
		},
	},
}
</script>

<style lang='scss'>
.sticky {
	position: fixed;
	z-index: 2;
}
</style>