<template>
	<div>
		<apexchart
			v-if="averages[0].data.length"
			class="apexchart apexchart-averages"
			type="line"
			height="270"
			:options="options"
			:series="averages"></apexchart>
		<div
			v-else
			class="text-center d-flex align-center justify-center"
			style="height: 200px">
			{{ offline ? 'لا يوجد اتصال بالانترنت' : 'لا توجد بيانات لعرضها' }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'AverageChart',

	props: {
		data: { type: Array, default: () => [] },
		offline: { type: Boolean, default: false },
	},

	computed: {
		options() {
			return {
				colors: ['#e88a30'],
				tooltip: {
					enabled: false,
				},
				dataLabels: {
					enabled: true,
					offsetY: -5,
					offsetX: 5,
					style: {
						fontSize: '12px',
						fontWeight: '400',
						colors: ['#ffffffcc'],
					},
					background: {
						borderRadius: 10,
						foreColor: '#e88a30',
						dropShadow: {
							enabled: true,
							top: 2,
							left: 1,
							blur: 1,
							color: '#000',
							opacity: 0.15,
						},
					},
				},
				stroke: {
					curve: 'straight',
					width: 2,
				},
				grid: {
					row: {
						colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				xaxis: {
					labels: {
						style: {
							fontFamily: 'Fairuz, sans-serif',
							fontSize: '10px',
						},
					},
				},
				yaxis: {
					max: Number(this.maxAverage),
					min: 0,
					tickAmount: 4,
					decimalsInFloat: 0,
					labels: {
						align: 'center',
						style: {
							fontSize: '10px',
						},
					},
				},
				chart: {
					toolbar: {
						show: false,
					},
				},
			};
		},
		maxAverage() {
			if (!this.data.length || Object.prototype.hasOwnProperty.call(this.data[0], 'MaxAverage')) {
				return 100;
			} else {
				let maxAverage = 0;
				this.data.forEach((e) => {
					if (e.SubjectMaxMark > maxAverage) {
						maxAverage = e.SubjectMaxMark;
					}
				});
				return maxAverage;
			}
		},
		averages() {
			const data = this.data
				// .map((e) => ({ ...e, Average: Math.random() * 50 }))
				.filter(
					(c) =>
						new Date(c.Month).getTime() <= new Date().getTime() &&
						c.Average &&
						!['NaN'].includes(c.Average),
				)
				.sort((a, b) => {
					return new Date(a.Month).getTime() - new Date(b.Month).getTime();
				})
				.slice(-8);

			// customize the array to fit the chart
			const chartData = data.map((e) => {
				return {
					// y is the average
					y: Number(Number(e.Average).toFixed(1)) || 0,

					// x is the date
					// apexchart don't allow for same x but as array it's play around
					x: [
						new Intl.DateTimeFormat('en-US', {
							month: 'short',
						}).format(new Date(e.Month)),
					],
				};
			});

			if (!chartData.length) return [{ data: [] }];

			chartData.push({
				y: null,
				x: [null],
			});

			// this is who to pass data to apexchart
			return [
				{
					data: chartData,
				},
			];
		},
	},
};
</script>

<style>
.apexchart-averages * {
	font-size: 14px;
}
</style>
