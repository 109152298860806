import * as types from '../mutations';

export default {
	namespaced: true,

	state: {
		messages: [],
	},

	actions: {
		fetchAll({ rootState, commit }) {
			return window.axios
				.get(
					`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/messages`,
				)
				.then((res) => {
					commit(types.MESSAGES, res.data);
					return res.data;
				});
		},
		sendMessage({ rootState }, { message, isParent }) {
			return window.axios.post(
				`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/send-message`,
				{
					Content: message,
					isParent,
				},
			);
		},
	},

	mutations: {
		[types.MESSAGES](state, messages) {
			state.messages = messages;
		},
	},
};
