<template>
	<div class="page">
		<v-card
			v-for="notification in notifications"
			:key="notification.Id"
			rounded="lg"
			class="overflow-hidden mb-2"
			ripple
			@click="goToMatchPage(notification.Type)"
		>
			<v-card-text class="pa-0">
				<v-row no-gutters>
					<v-col
						cols="auto"
						class="d-flex justify-center align-center myOrange icon-container"
					>
						<v-icon size="22" color="white">{{getIcon(notification.Type)}}</v-icon>
					</v-col>
					<v-col class="flex-grow-1 px-3 py-2">
						<p class="mb-0">
							{{notification.Text}}
						</p>
						<span v-if="$vuetify.breakpoint.width < 530" class="text-body-1 font-weight-bold">
							{{dateFormater(notification.NotifiDate)}}
						</span>
					</v-col>

					<template v-if="$vuetify.breakpoint.width >= 530">
						<v-divider vertical/>
						
						<v-col cols="2" class="d-flex justify-center align-center text-body-1 font-weight-bold">
							{{dateFormater(notification.NotifiDate)}}
						</v-col>
					</template>
				</v-row>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { notificationTypes } from '@/helpers/enums';
import { mapState } from 'vuex';
import { dateFormater } from '@/helpers/helpers';
export default {
	name: 'NotificationView',
	
	computed: {
		...mapState({
			notifications: state => state.notifications.notifications
		})
	},

	methods: {
		getIcon(type) {
			switch (type) {
			case notificationTypes.message : return 'mdi-message-processing';
			case notificationTypes.attendance : return 'mdi-clipboard-check';
			case notificationTypes.newMark : return 'mdi-clipboard-edit';
			case notificationTypes.newMarks : return 'mdi-clipboard-edit';
			case notificationTypes.schedule : return 'mdi-calendar-clock';
			case notificationTypes.newTest : return 'mdi-clipboard-text-clock';
			case notificationTypes.testsSchedule : return 'mdi-clipboard-text-clock';
			case notificationTypes.transport : return 'mdi-bus';
			case notificationTypes.notes : return 'mdi-note-text';
			}
		},

		goToMatchPage (type) {
			let routeName;
			switch (type) {
			case notificationTypes.message: routeName = 'chat'; break;
			case notificationTypes.attendance: routeName = 'attendance'; break;
			case notificationTypes.newMark: routeName = 'grades'; break;
			case notificationTypes.newMarks: routeName = 'grades'; break;
			case notificationTypes.schedule: routeName = 'schedule'; break;
			case notificationTypes.newTest: routeName = 'exams'; break;
			case notificationTypes.testsSchedule: routeName = 'exams'; break;
			case notificationTypes.notes: routeName = 'behavior'; break;
			};
			if (routeName) this.$router.push({ name: routeName })
		},

		dateFormater
	},

	created() {
		if (!this.notifications.length) {
			this.$eventBus.$emit('page-loader', true);
			this.$store.dispatch('notifications/fetchAll').finally(() => {
				this.$eventBus.$emit('page-loader', false);
			})
		}
	}
};
</script>

<style lang="scss" scoped>
.icon-container {
	width: 60px;
}
</style>