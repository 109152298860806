export default {
	namespaced: true,

	state: {},

	actions: {
		fetchSchedule({ rootState }) {
			return window.axios
				.get(
					`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/student-schedule`,
				)
				.then((res) => {
					return res.data;
				});
		},
	},
};
