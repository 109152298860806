import { notificationOptions, notificationPageRedirect, onGetNotification } from '@/notifications/notifications.config';

export function getNotificationOptions (body, tag = null) {
	return {
		body: body,
		icon: notificationOptions.icon,
		dir: notificationOptions.dir,
		lang: notificationOptions.lang,
		vibrate: [200, 100, 200],
		renotify: true,
		tag,
	};
}

export function notificationTypePage(type, isServiceWorker = false) {
	let page = '/';
	const pageRedirect = notificationPageRedirect.find(c => c.type === type);
	if (pageRedirect && !isServiceWorker) {
		page = pageRedirect.routeName || pageRedirect.redirect || '/';
	} else if (pageRedirect && isServiceWorker) {
		page = pageRedirect.redirect || '/';
	}
	return page;
}

export function onMessageHandler () {
	onGetNotification();
}