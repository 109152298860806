import moment from 'moment';

moment.updateLocale('ar-ly', {
	months: [
		'كانون الثاني',
		'شباط',
		'آذار',
		'نيسان',
		'أيار',
		'حزيران',
		'تموز',
		'آب',
		'أيلول',
		'تشرين الأول',
		'تشرين الثاني',
		'كانون الأول',
	],
});
export default moment