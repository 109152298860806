<template>
	<div class="page">
		<div
			v-for="(exam, i) in exams"
			:key="i">
			<div class="d-flex rounded-lg overflow-hidden mb-2">
				<div
					class="date d-flex flex-column justify-center primary white--text pa-2 pe-4 text-center">
					<div>{{ new Date(exam.Date).getDate() }}</div>
					<div>{{ getMonth(new Date(exam.Date)) }}</div>
				</div>
				<div class="main-card flex-grow-1 pa-4 ps-5">
					<div class="d-flex justify-space-between align-center">
						<div>
							<div class="subject-name">
								{{ exam.SubjectName }}
							</div>
							<div
								class="subject-description text-caption text--secondary">
								{{ exam.Details }}
							</div>
						</div>
						<div class="time text-caption text--secondary">
							{{ getTime(new Date(exam.StartTime)) }} -
							{{ getTime(new Date(exam.EndTime)) }}
						</div>
					</div>
				</div>
			</div>
			<div
				class="space-card mb-2 rounded-lg"
				v-if="exam.spaces">
				{{ exam.spaces }} أيام فراغ
			</div>
		</div>
		<div
			class="text-center py-5 text-h6"
			v-if="!exams.length">
			لا توجد مذاكرات
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { diffDate, getDay, getMonth, getTime } from '@/helpers/helpers';
export default {
	name: 'ExamProgram',

	data() {
		return {
			exams: [],
		};
	},

	computed: {
		...mapState({
			examSchedule: (state) => state.exams.examSchedule,
		}),
	},

	methods: {
		diffDate,
		getDay,
		getMonth,
		getTime,
		fetch() {
			this.$eventBus.$emit('page-loader', true);
			this.$store
				.dispatch('exams/fetchSchedule')
				.then(() => {
					this.exams = this.examSchedule;
					this.exams.sort((a, b) => a.Date - b.Date);
					for (let i = 0; i < this.exams.length - 1; i++) {
						const p = this.exams[i];
						const l = this.exams[i + 1];
						p.spaces = this.diffDate(
							new Date(l.Date),
							new Date(p.Date),
						);
					}
				})
				.finally(() => {
					this.$eventBus.$emit('page-loader', false);
				});
		},
	},

	mounted() {
		this.fetch();
	},
};
</script>

<style lang="scss" scoped>
.space-card {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #666;
	background-color: #d5d5d5;
	padding: 18px;
}

$space: 13px;
.date {
	clip-path: polygon(
		100% 0,
		100% 100%,
		#{0 + $space} 100%,
		0 50%,
		#{0 + $space} 0
	);
	min-width: 70px;
	max-width: 70px;
}
.time {
	min-width: max-content;
	max-width: max-content;
}
.main-card {
	margin-right: -5px;
	clip-path: polygon(0 0, 100% 0, calc(100% - $space) 50%, 100% 100%, 0 100%);
	background: var(--v-white-lighten1);
}
</style>
