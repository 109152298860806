<template>
	<v-container
		class="primary lighten-2 fill-height border-right-bottom"
		fluid>
		<div
			class="text-center mx-auto"
			style="width: 100%; max-width: 600px">
			<v-img
				class="mx-auto mb-7"
				src="../assets/Logo.png"
				height="8rem"
				max-width="8rem"
				contain></v-img>
			<div class="text-h4 mb-6 white--text">تسجيل الدخول</div>
			<v-form
				class="mb-7"
				ref="form"
				@submit.prevent="submit"
				v-model="valid"
				lazy-validation>
				<v-text-field
					hide-details
					solo
					class="rounded-lg mb-3"
					background-color="white"
					color="black"
					required
					v-model="name"
					label="اسم المستخدم"
					:rules="rules.required">
					<template v-slot:prepend-inner>
						<v-icon color="primary">mdi-account</v-icon>
					</template>
				</v-text-field>
				<v-text-field
					hide-details
					solo
					class="rounded-lg mb-8"
					background-color="white"
					color="black"
					required
					v-model="password"
					label="كلمة المرور"
					:type="showPassword ? 'text' : 'password'"
					:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
					@click:append="showPassword = !showPassword"
					:rules="rules.required">
					<template v-slot:prepend-inner>
						<v-icon color="primary">mdi-key</v-icon>
					</template>
				</v-text-field>
				<v-btn
					large
					class="rounded-lg"
					color="secondary white--text"
					type="submit"
					:loading="loading"
					:disabled="loading || $isOffline.value">
					تسجيل الدخول
				</v-btn>

				<v-btn
					v-if="activeUser"
					color="white"
					plain
					icon
					absolute
					top
					right
					:to="{ name: 'home' }">
					<v-icon>mdi-arrow-right</v-icon>
				</v-btn>
				<div
					class="mt-4"
					v-if="!activeUser && users.length">
					<v-btn
						color="white"
						text
						:to="{ name: 'accounts' }">
						الحسابات المسجل بها
					</v-btn>
				</div>
			</v-form>

			<div class="text-center">
				<p class="mb-1">تابع نشاطاتنا على</p>
				<v-btn
					:href="config.about.facebook"
					target="_blank"
					rounded
					color="sec"
					elevation="4"
					outlined>
					facebook
				</v-btn>
			</div>
		</div>
	</v-container>
</template>

<script>
import { MD5 } from 'crypto-js';
import { mapState } from 'vuex';
import rules from '@/helpers/validation rules';
import config from '@/helpers/config';

export default {
	name: 'Login',
	data() {
		return {
			name: null,
			password: null,

			valid: true,
			rules,
			loading: false,
			showPassword: false,

			config,
		};
	},
	computed: {
		...mapState({
			users: (state) => state.login.users,
			activeUser: (state) => state.login.activeUser,
		}),
	},
	methods: {
		MD5,
		submit() {
			if (!this.$refs.form.validate()) {
				this.$eventBus.$emit('show-snackbar', true, 'يرجى إدخال اسم المستخدم وكلمة السر.');
				return;
			}

			this.loading = true;
			this.$store
				.dispatch('login/login', {
					userName: this.name,
					password: this.MD5(this.password).toString(),
				})
				.then(() => {
					this.$router.push({ name: 'home' });
					this.$store.dispatch('clearAll');

					this.$eventBus.$emit('show-snackbar', false, this.name + ' اهلا وسهل بك.');
				})
				.catch(() => {
					this.$eventBus.$emit('show-snackbar', true, 'حدث خطأ ما.');
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style>
.border-right-bottom {
	border-radius: 0 0 0 200px;
}
</style>
