<template>
	<div class="page">
		<!-- chart -->
		<v-card
			rounded="lg"
			elevation="3"
			class="mb-2 pt-2 pr-2 mx-auto"
			max-width="700">
			<div
				class="text-center pt-1 d-flex align-center flex-column"
				v-if="
					!staticsAverage.IsNokhba &&
					staticsAverage.FirstSemesterStart &&
					staticsAverage.FirstSemesterEnd &&
					staticsAverage.SecondSemesterStart &&
					staticsAverage.SecondSemesterEnd &&
					new Date() >= new Date(staticsAverage.SecondSemesterStart)
				">
				المعدل التراكمي لكل المواد خلال الفترة السابقة
				<v-radio-group
					dense
					row
					mandatory
					v-model="semesterType">
					<v-radio
						dense
						hide-details
						class="mx-1"
						:value="0"
						:label="'الكل'"></v-radio>
					<v-radio
						dense
						hide-details
						class="mx-1"
						:value="1"
						:label="'فصل اول'"></v-radio>
					<v-radio
						dense
						hide-details
						class="mx-1"
						:value="2"
						:label="'فصل ثاني'"></v-radio>
				</v-radio-group>
			</div>
			<average-chart :data="averages"></average-chart>
		</v-card>

		<!-- average -->
		<v-card
			rounded="lg"
			elevation="3"
			class="px-5 py-2 mb-2 mx-auto"
			max-width="700">
			<v-row no-gutters>
				<v-col
					cols="7"
					class="text-center">
					<div>
						<span
							v-if="staticsAverage.MonthlyImprove !== '-'"
							class="text-h4 font-weight-bold"
							:class="
								staticsAverage.MonthlyImprove[0] !== '-' ? 'myGreen--text' : 'error--text'
							"
							dir="ltr">
							{{ staticsAverage.MonthlyImprove }}
							<v-icon
								v-if="staticsAverage.MonthlyImprove[0] !== '-'"
								color="myGreen"
								class="mx-n4 text-h4">
								mdi-chevron-double-up
							</v-icon>
							<v-icon
								v-else
								color="error"
								class="mx-n4 text-h4">
								mdi-chevron-double-down
							</v-icon>
						</span>
						<span
							v-else
							class="text-h4 font-weight-bold grey--text">
							-
						</span>
					</div>
					<div>تغير عن الشهر الماضي</div>
				</v-col>

				<v-divider vertical></v-divider>

				<v-col
					cols="5"
					class="text-center">
					<div v-if="staticsAverage.Average.split('/').length > 1">
						<span class="text-h4 font-weight-bold myOrange--text">
							{{ staticsAverage.Average.split('/')[0] }}
						</span>
						/
						{{ staticsAverage.Average.split('/')[1] }}
					</div>
					<div v-else-if="staticsAverage.Average !== 'NaN%'">
						<span class="text-h4 font-weight-bold myOrange--text">
							{{ staticsAverage.Average }}
						</span>
					</div>
					<div
						v-else
						class="text-h4 font-weight-bold grey--text">
						-
					</div>
					<div>معدل تراكمي</div>
				</v-col>
			</v-row>
		</v-card>

		<!-- rank -->
		<!-- <v-card
			rounded="lg"
			elevation="3"
			class="pa-4 mb-4 mx-auto"
			max-width="700"
			v-if="studentRank[1].SortStudents || studentRank[0].SortStudents">
			<div class="d-flex justify-space-around align-end">
				<div class="myBlue--text text-h5">ترتيب الطالب</div>
				<div v-if="studentRank[1].SortStudents">
					<span class="myBlue--text text-h4 font-weight-bold">
						{{ studentRank[1].SortStudents?.Rank }}
					</span>
					<span style="width: max-content; display: inline-block"> على الصف </span>
				</div>
				<div v-if="studentRank[0].SortStudents">
					<span class="myBlue--text text-h4 font-weight-bold">
						{{ studentRank[0].SortStudents?.Rank }}
					</span>
					<span style="width: max-content; display: inline-block"> على الشعبة </span>
				</div>
			</div>
		</v-card> -->

		<!-- month select -->
		<v-sheet
			class="mx-auto mb-1 px-2 transparent"
			max-width="700">
			<span class="text-h6">التقرير الشهري:</span>
		</v-sheet>
		<v-select
			:disabled="$isOffline.value"
			v-model="selectedMonth"
			:items="
				staticsAverage.CourseMonths?.map((e) => ({
					value: e,
					text: getShortDate(new Date(e)),
				}))
			"
			dark
			@change="changeReport"
			class="mb-2 mx-auto"
			background-color="primary"
			rounded
			solo
			mandatory
			label="اختر شهر"
			hide-details
			style="max-width: 700px"></v-select>

		<!-- <v-card
			v-if="monthSelected"
			rounded="lg"
			elevation="3"
			class="pa-4 mx-auto"
			max-width="700">
			<div v-if="loadingReport">
				<div class="d-flex justify-center">
					<v-progress-circular
						indeterminate
						color="primary"></v-progress-circular>
				</div>
			</div>
			<template v-else-if="monthStatics">
				<div class="d-flex justify-space-around align-end mb-4">
					<div class="text-body-1">المعدل الشهري</div>
					<div v-if="monthStatics.MonthlyAverage.split('/').length > 1">
						<span class="text-h4 font-weight-bold myOrange--text">
							{{ monthStatics.MonthlyAverage.split('/')[0] }}
						</span>
						/
						{{ monthStatics.MonthlyAverage.split('/')[1] }}
					</div>
					<div v-else-if="monthStatics.MonthlyAverage !== 'NaN%'">
						<span class="text-h4 font-weight-bold myOrange--text">
							{{ monthStatics.MonthlyAverage }}
						</span>
					</div>
					<div
						v-else
						class="text-h4 font-weight-bold grey--text">
						-
					</div>
				</div>
				<div class="d-flex justify-space-around align-end">
					<div class="myBlue--text text-h5">ترتيب الطالب</div>
					<div v-if="monthStatics.SpecialtyRank">
						<span class="myBlue--text text-h4 font-weight-bold">
							{{ monthStatics.SpecialtyRank }}
						</span>
						<span style="width: max-content; display: inline-block"> على الصف </span>
					</div>
					<div v-if="monthStatics.Rank">
						<span class="myBlue--text text-h4 font-weight-bold">
							{{ monthStatics.Rank }}
						</span>
						<span style="width: max-content; display: inline-block"> على الشعبة </span>
					</div>
				</div>
			</template>
			<div
				v-else
				class="text-center text-subtitle-1 text--secondary py-1">
				لا توجد بيانات لهذا الشهر
			</div>
		</v-card> -->

		<div v-if="loadingReport">
			<div class="d-flex justify-center">
				<v-progress-circular
					indeterminate
					color="primary"></v-progress-circular>
			</div>
		</div>
		<template v-else-if="monthReport">
			<v-card
				rounded="lg"
				elevation="3"
				class="pa-2 mb-4 mx-auto"
				max-width="700">
				<div class="d-flex justify-space-around align-center">
					<div class="text-center">
						<div class="myBlue--text text-h4 font-weight-bold">
							{{ monthReport.MonthlyAverage }}
						</div>
						<div class="myBlue--text text-h5 font-weight-bold">{{ monthReport.Rate }}</div>
					</div>
					<div>
						<span class="myBlue--text text-h4 font-weight-bold">
							{{ monthReport.Rank }}
						</span>
						<span
							class="text-body-2"
							style="width: max-content; display: inline-block">
							على الشعبة
						</span>
					</div>
					<div v-if="monthReport.CountOfTotalMark !== '0'">
						<span class="myBlue--text text-h4 font-weight-bold">
							{{ monthReport.CountOfTotalMark }}
						</span>
						<span
							class="text-body-2"
							style="width: max-content; display: inline-block">
							علامات تامة
						</span>
					</div>
				</div>
			</v-card>

			<v-card
				rounded="lg"
				elevation="3"
				class="pa-4 mb-4 mx-auto"
				max-width="700">
				<v-data-table
					:headers="reportHeaders"
					:items="monthReport.Marks.map((e) => e).sort((a, b) => b.Test.Date - a.Test.Date)"
					mobile-breakpoint="0"
					hide-default-footer
					:items-per-page="-1"
					dense>
					<template v-slot:item.date="{ item }">
						{{ getMonthAndDate(item.Test.Date) }}
					</template>
					<template v-slot:item.subject="{ item }">
						{{ item.Test.Details }}
					</template>
					<template v-slot:item.type="{ item }">
						{{ item.Test.TestType }}
					</template>
					<template v-slot:item.grade="{ item }">
						<template v-if="!item.IsAbsent"> {{ item.Mark }}/{{ item.TestMaxMark }} </template>
						<template v-else> لم يقدم </template>
					</template>
				</v-data-table>
				<v-divider></v-divider>
				<div class="pt-3 px-5 d-flex align-center justify-end">
					<div class="font-weight-regular">المعدل الكلي</div>
					<div
						dir="ltr"
						class="text-h6">
						{{ monthReport.AverageAsSum }}
						:
					</div>
				</div>
				<div class="pt-1 px-5 d-flex align-center justify-end">
					<div
						class="font-weight-regular"
						style="line-height: 1em">
						المعدل الكلي
						<br />
						بدون ديانة
					</div>
					<div
						dir="ltr"
						class="text-h6">
						{{ monthReport.AverageAsSumWithOutReligion }}
						:
					</div>
				</div>
			</v-card>
		</template>
		<div
			v-else-if="searchedReport"
			class="text-center text-subtitle-1 text--secondary py-1">
			لم يصدر تقرير لهذا الشهر
		</div>
	</div>
</template>

<script>
import AverageChart from '@/components/AverageChart.vue';
import { mapState } from 'vuex';
import { getShortDate, getMonthAndDate } from '@/helpers/helpers';

export default {
	name: 'Statistics',

	components: {
		AverageChart,
	},

	data() {
		return {
			loadingReport: false,

			semesterType: 0,
			// studentRank: null,
			// getBetter: 0,
			// monthStatics: null,

			selectedMonth: null,
			monthReport: null,
			searchedReport: false,

			reportHeaders: [
				{ text: 'المادة', value: 'Test.SubjectName', class: 'primary--text', sortable: false },
				{
					text: 'المحتوى',
					value: 'subject',
					sortable: false,
					class: 'primary--text',
				},
				{ text: 'التاريخ', value: 'date', class: 'primary--text', sortable: false },
				{
					text: 'النوع',
					value: 'type',
					sortable: false,
					class: 'primary--text',
				},
				{
					text: 'العلامة',
					value: 'grade',
					sortable: false,
					class: 'primary--text',
				},
			],
		};
	},

	computed: {
		averages() {
			/* eslint-disable */
			switch (this.semesterType) {
				case 1:
					return this.staticsAverage.StaticsAverage.filter(
						(e) =>
							new Date(e.Month) >=
								new Date(
									new Date(this.staticsAverage.FirstSemesterStart).setDate(1),
								).setHours(0, 0, 0, 0) &&
							new Date(e.Month) <=
								new Date(new Date(this.staticsAverage.FirstSemesterEnd).setDate(1)).setHours(
									0,
									0,
									0,
									0,
								),
					);
				case 2:
					return this.staticsAverage.StaticsAverage.filter(
						(e) =>
							new Date(e.Month) >=
								new Date(
									new Date(this.staticsAverage.SecondSemesterStart).setDate(1),
								).setHours(0, 0, 0, 0) &&
							new Date(e.Month) <=
								new Date(new Date(this.staticsAverage.SecondSemesterEnd).setDate(1)).setHours(
									0,
									0,
									0,
									0,
								),
					);
				default:
					return this.staticsAverage.StaticsAverage;
			}
			/* eslint-enable */
		},

		...mapState({
			staticsAverage: (state) => state.statistics.statistics,
		}),
	},

	methods: {
		getShortDate,
		getMonthAndDate,
		// getGetBetter(val) {
		// 	this.getBetter = val;
		// },
		changeReport() {
			this.loadingReport = true;
			this.$store
				.dispatch('statistics/fetchReport', {
					month: this.selectedMonth,
				})
				.then((res) => {
					this.monthReport = res;
					this.searchedReport = true;
				})
				.finally(() => {
					this.loadingReport = false;
				});
		},
		fetch() {
			this.$eventBus.$emit('page-loader', true);
			this.$store.dispatch('statistics/fetchAverages').finally(() => {
				this.$eventBus.$emit('page-loader', false);
			});
		},
	},

	mounted() {
		this.fetch();
	},
};
</script>

<style>
tr:nth-child(even) {
	background-color: #eee;
}
</style>
