import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import config from '../helpers/config';

Vue.use(Vuetify);

export default new Vuetify({
	rtl: true,
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				// to change
				primary: config.colors.main,
				secondary: config.colors.sec,
				alt: config.colors.alt,

				// static
				white: '#fbfbfb',
				myGreen: '#259952',
				myOrange: '#ee8c2d',
				myBlue: '#4175bf',
				homeOrange: '#fb5d32',
				homePink: '#f04770',
				homeBlue: '#07a0c2',
				homeYellow: '#f49237',
			},
		},
	},
});
