import * as types from '../mutations';

export default {
	namespaced: true,

	state: {
		attendance: null,
		statistics: null,
		lastMark: null,
		version: null,
	},

	actions: {
		fetchAttendance({ commit, rootState, state }) {
			return (
				state.attendance ||
				window.axios
					.get(
						`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/attendance-rate`,
					)
					.then((res) => {
						commit(types.HOME_ATTENDANCE, res.data);
						return res.data;
					})
			);
		},
		fetchStatistics({ commit, rootState, state }) {
			return (
				state.statistics ||
				window.axios
					.get(
						`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/get-monthly-statistics`,
					)
					.then((res) => {
						commit(types.HOME_STATISTICS, res.data);
						return res.data;
					})
			);
		},
		fetchLastMark({ commit, rootState, state }) {
			return (
				state.lastMark ||
				window.axios
					.get(
						`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/last-mark`,
					)
					.then((res) => {
						commit(types.HOME_LAST_MARK, res.data);
						return res.data;
					})
			);
		},
		fetchVersion({ commit, rootState, state }) {
			return (
				state.version ||
				window.axios.get('/version').then((res) => {
					commit(types.HOME_VERSION, res.data);
					return res.data;
				})
			);
		},
		clear({ commit }) {
			commit('CLEAR');
		},
	},

	mutations: {
		[types.HOME_ATTENDANCE](state, attendance) {
			state.attendance = attendance;
		},
		[types.HOME_STATISTICS](state, statistics) {
			state.statistics = statistics;
		},
		[types.HOME_LAST_MARK](state, lastMark) {
			state.lastMark = lastMark;
		},
		[types.HOME_VERSION](state, version) {
			state.version = version;
		},
		CLEAR(state) {
			state.attendance = null;
			state.statistics = null;
			state.lastMark = null;
			state.version = null;
		},
	},
};
