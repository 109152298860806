<template>
	<v-sheet
		color="transparent"
		class="d-flex"
		:class="{ sent: !message.IsFromAdmin }">
		<v-avatar
			class="mx-2 white--text"
			:color="!message.IsParent ? 'primary' : 'secondary'"
			:size="$vuetify.breakpoint.mobile ? 30 : 50">
			<img
				src="../assets/Logo.png"
				alt=""
				v-if="message.IsFromAdmin" />
			<span v-else-if="message.IsParent">
				<v-icon color="white">mdi-account-supervisor</v-icon>
			</span>
			<span v-else>{{ activeUser.userName.slice(0, 2) }}</span>
		</v-avatar>
		<v-card
			class="message-text pa-3 rounded-md"
			:class="message.IsFromAdmin ? 'white' : 'primary lighten-4'">
			<div>
				{{ message.Content }}
			</div>
			<v-divider class="mt-3 mb-1"></v-divider>
			<div class="d-flex align-center text-caption text--secondary">
				{{ dateFormaterAndTime(new Date(message.SentAt)) }}
			</div>
		</v-card>
	</v-sheet>
</template>

<script>
import { dateFormaterAndTime } from '@/helpers/helpers';
import { mapState } from 'vuex';
export default {
	name: 'Message',
	props: ['message'],
	computed: {
		...mapState({
			activeUser: (state) => state.login.activeUser,
		}),
	},
	methods: {
		dateFormaterAndTime,
	},
};
</script>

<style lang="scss" scoped>
.sent {
	flex-direction: row-reverse;
}
.message-text {
	width: 70%;
}
</style>
