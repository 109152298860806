<template>
	<div>
		<div
			class="workingHours-container"
			dir="ltr">
			<div
				class="workingHours-inner"
				dir="rtl">
				<working-hours
					v-if="date.length"
					:date="date"
					:weekNames="weekNames"></working-hours>
			</div>
		</div>
	</div>
</template>

<script>
import WorkingHours from '@/components/WorkingHours';

export default {
	name: 'ScheduleView',

	components: { WorkingHours },

	data() {
		return {
			date: [],
			weekNames: [
				'الجمعة',
				'السبت',
				'الأحد',
				'الأثنين',
				'الثلاثاء',
				'الأربعاء',
				'الخميس',
			],
		};
	},

	methods: {
		fetch() {
			this.$eventBus.$emit('page-loader', true);
			this.$store
				.dispatch('schedule/fetchSchedule')
				.then((res) => {
					const schedule = res;

					// costmaize the dates
					this.date = schedule.map((item) => {
						return {
							class: item.RoomName,
							name: item.SubjectName,
							teacher: item.TeacherName,
							x: this.weekNames[item.Day],
							y: [
								new Date(item.StartDate).getTime(),
								new Date(
									item.StartDate + item.Duration * 1000 * 60,
								).getTime(),
							],
						};
					});
				})
				.finally(() => {
					this.$eventBus.$emit('page-loader', false);
				});
		},
	},
	mounted() {
		this.fetch();
	},
};
</script>

<style scoped>
.workingHours-container {
	width: 100%;
	overflow-x: auto;
}
.workingHours-inner {
	min-width: 1000px;
	overflow: hidden;
}
</style>
