<template>
	<v-tabs
		v-model="localValue"
		show-arrows
		fixed-tabs
		hide-slider
		centered
		center-active
		background-color="transparent"
		height="56"
		active-class="myBlue white--text"
		:style="{ maxWidth: `${maxWidth}px`}"
	>
		<div
			v-for="day in selectedMonthDays"
			:key="day.dayNumber"
			class="days-container mx-2"
		>
			<div class="mb-1 myOrange--text">{{day.shortName}}</div>
			<v-tab
				class="day-item rounded-lg px-0"
				style="min-width: 0; overflow: hidden"
				:href="`#${day.date}`"
			>
				<span class="text-body-1">{{day.dayNumber}}</span>
			</v-tab>
		</div>
	</v-tabs>
</template>

<script>
import moment from 'moment';
export default {
	name: 'VMonthDays',

	props: {
		month: { type: String, default: new Date().toISOString().slice(0, 7) },
		value: { type: String, default: '' },
		maxWidth: { type: Number }
	},

	computed: {
		selectedMonthDays() {
			const daysCount = moment(this.month, 'YYYY-MM').daysInMonth();
			const days = [];
			for (let i = 1; i <= daysCount; i++) {
				const date = this.month + '-' + i.toString().padStart(2, 0);
				days.push({
					dayNumber: i.toString().padStart(2, 0),
					shortName: moment(date).locale('ar').format('ddd'),
					date
				})
			}
			return days;
		},

		localValue: {
			get() {
				return this.value;
			},
			set(newVal) {
				this.$emit('input', newVal);
			}
		}
	}
}
</script>

<style lang="scss">
.days-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 32px;
	
	.day-item {
		width: 20px;
		height: 20px;
	}
}
</style>