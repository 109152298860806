<template>
	<div
		v-if="$isOffline.value"
		class="text-h4 text--secondary text-center d-flex justify-center align-center"
		style="height: 50vh">
		لا يوجد الاتصال بالانترنت
	</div>
	<div
		v-else-if="!center"
		class="d-flex justify-center align-center"
		style="height: 90vh">
		<Loader></Loader>
	</div>
	<div
		v-else
		class="page bus-page">
		<!-- assistant -->
		<v-card
			class="px-5 py-3 mb-4"
			rounded="lg"
			elevation="1">
			<div class="d-flex justify-space-between align-center">
				<div>
					<div class="text--secondary">المشرف</div>
					<div class="text-body-1">{{ busInfo.AssistantName }}</div>
				</div>
				<a :href="`tel://${busInfo.AssistantPhone}`">
					<div class="rounded-xl py-1 px-3 white--text success">
						<v-icon color="white">mdi-phone</v-icon>
						اتصال
					</div>
				</a>
			</div>
		</v-card>

		<div class="d-flex mb-4">
			<!-- bus info -->
			<v-card
				class="px-5 py-3 me-4 flex-grow-1"
				rounded="lg"
				elevation="1">
				<div class="d-flex align-center mb-2">
					<div>
						<v-avatar
							color="grey lighten-1 me-3"
							size="60">
							<v-icon
								color="grey darken-3"
								x-large>
								mdi-bus
							</v-icon>
						</v-avatar>
					</div>
					<div>
						<div class="text--secondary">الحافلة</div>
						<div class="text-body-1">{{ busInfo.BusNo }}</div>
					</div>
				</div>
				<div class="d-flex align-center">
					<div>
						<v-avatar
							color="grey lighten-1 me-3"
							size="60">
							<v-icon
								color="grey darken-3"
								x-large>
								mdi-account-tie-hat
							</v-icon>
						</v-avatar>
					</div>
					<div>
						<div class="text--secondary">السائق</div>
						<div class="text-body-1">{{ busInfo.DriverName }}</div>
					</div>
				</div>
			</v-card>

			<!-- time arrival -->
			<v-card
				class="px-5 py-3"
				rounded="lg"
				elevation="1">
				<!-- TODO: remove mt-5 class, add text-caption class and remove <br /> tag -->
				<div class="text--secondary mt-5">موعد وصل <br />الحافلة</div>
				<div class="text-h4 success--text">
					{{ getTime(new Date(busInfo.ArrivalTime - 1000 * 60 * 60 * 2)) }}
				</div>
				<template v-if="false">
					<v-divider class="my-2"></v-divider>
					<div class="text--secondary text-caption">التنبية قبل الموعد بربع ساعة</div>
					<v-switch
						v-model="getNotification"
						class="mt-0"
						hide-details></v-switch>
				</template>
			</v-card>
		</div>

		<!-- map -->
		<div class="mb-4 overflow-hidden rounded-lg">
			<l-map
				ref="myMap"
				@ready="displayMap"
				:zoom="13"
				style="height: 200px; width: 100%"
				:center="center"
				:options="{ zoomControl: false }">
				<l-tile-layer :url="url"></l-tile-layer>
				<l-marker
					:lat-lng="center"
					:icon="schoolIcon"></l-marker>
			</l-map>
		</div>

		<!-- button -->
		<v-btn
			@click="openMap"
			:loading="loadingMap"
			:disabled="loadingMap"
			class="mb-4"
			color="primary"
			block
			large
			rounded>
			عرض موقع الحفالة على الخريطة
		</v-btn>

		<!-- status -->
		<div v-if="status.length">
			<div>الاحداث</div>
			<div
				v-for="(state, i) in status"
				:key="i">
				<div class="text-body-1 primary--text mt-4 mb-1">
					{{ dateFormater(new Date(state.Date)) }}
				</div>
				<v-card
					v-for="(event, j) in state.BusStatus"
					:key="i + ' ' + j"
					class="px-5 py-3 mb-1"
					rounded="lg"
					elevation="2">
					<div class="d-flex justify-space-between">
						<div class="d-flex align-center">
							<v-sheet
								style="width: 2rem; height: 2rem"
								class="homeOrange rounded-circle d-flex justify-center align-center me-2">
								<v-icon
									class="white--text"
									small>
									mdi-bell
								</v-icon>
							</v-sheet>
							<span> {{ event.Status }} </span>
						</div>
						<div>{{ getTime(new Date(event.Time)) }}</div>
					</div>
				</v-card>
			</div>
		</div>

		<!-- overlays map -->
		<div
			class="full-screen"
			v-if="showMap">
			<l-map
				style="height: 100vh; width: 100vw"
				:max-bounds="bounds"
				:zoom="zoom"
				:maxZoom="16"
				:minZoom="8"
				:center="center">
				<l-tile-layer :url="url"></l-tile-layer>
				<!-- bus -->
				<l-marker
					:lat-lng.sync="busMarker"
					:icon="busIcon"></l-marker>

				<!-- home -->
				<l-marker
					v-if="busInfo.HomeLocation.Lat && busInfo.HomeLocation.Lng"
					:lat-lng="[busInfo.HomeLocation.Lat, busInfo.HomeLocation.Lng]"
					:icon="homeIcon">
				</l-marker>

				<!-- school -->
				<l-marker
					:lat-lng="[busInfo.SchoolLocation.Lat, busInfo.SchoolLocation.Lng]"
					:icon="schoolIcon">
				</l-marker>
			</l-map>

			<!-- close -->
			<v-btn
				icon
				fab
				class="overlay-close"
				@click="showMap = false">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</div>
	</div>
</template>

<script>
import L from 'leaflet';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { dateFormater, getTime } from '@/helpers/helpers';
import Loader from '@/components/Loader.vue';

export default {
	name: 'BusView',
	components: {
		Loader,
		LMap,
		LTileLayer,
		LMarker,
	},
	data() {
		return {
			loadingMap: false,
			showMap: false,
			busInfo: null,
			getNotification: false,
			status: [],

			zoom: 13,
			url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
			bounds: L.latLngBounds([
				[40, 30],
				[30, 45],
			]),
			busIcon: L.icon({
				iconUrl: './marker.png',
				iconSize: [40, 40],
			}),
			schoolIcon: L.icon({
				iconUrl: './school-pin.png',
				iconSize: [70, 70],
			}),
			homeIcon: L.icon({
				iconUrl: './home-pin.png',
				iconSize: [40, 40],
			}),

			center: null,
			busMarker: [0, 0],

			intervalId: null,
		};
	},

	methods: {
		dateFormater,
		getTime,
		openMap() {
			const getPoints = () => {
				return this.$store.dispatch('bus/fetchPoints').catch(() => {
					clearInterval(this.intervalId);
				});
			};

			this.loadingMap = true;
			getPoints().then((res) => {
				this.loadingMap = false;
				if (!res) {
					this.$eventBus.$emit('show-snackbar', true, 'موقع الباص غير متاح حاليا');
					clearInterval(this.intervalId);
				} else {
					this.showMap = true;
					this.busMarker = [res.Lat, res.Lng];
				}
			});
			this.intervalId = setInterval(getPoints, 1000 * 10);
		},
		displayMap() {
			const map = this.$refs.myMap.mapObject;
			map.dragging.disable();
			map.touchZoom.disable();
			map.doubleClickZoom.disable();
			map.scrollWheelZoom.disable();
			map.boxZoom.disable();
			map.keyboard.disable();
		},
		fetch() {
			Promise.all([
				this.$store.dispatch('bus/fetchBusInfo').then((res) => {
					this.busInfo = res;
					this.center = [res.SchoolLocation.Lat, res.SchoolLocation.Lng];
				}),
				this.$store.dispatch('bus/fetchStatus').then((res) => {
					this.status = res;
				}),
			]);
		},
	},

	mounted() {
		this.fetch();
	},

	beforeDestroy() {
		clearInterval(this.intervalId);
	},
};
</script>

<style>
.full-screen {
	position: fixed;
	height: 100vh;
	width: 100vw;
	left: 0;
	top: 0;
	z-index: 1000;
}
.overlay-close {
	position: absolute;
	top: 0px;
	right: 20px;
	z-index: 1001;
}
.leaflet-bottom.leaflet-right {
	display: none;
}
.bus-page {
	max-width: 700px;
	margin: auto;
}
</style>
