import * as types from '../mutations';

export default {
	namespaced: true,

	state: {
		users: [],
		centerId: null,
		activeUser: null,
	},

	actions: {
		login({ commit }, { userName, password, centerId = null }) {
			return window.axios
				.post(`/students/${userName}/login`, null, {
					headers: {
						'X-Password': password,
					},
				})
				.then((res) => {
					const center = res.data.center;
					commit(types.LOGIN, {
						centerId: center,
						userName: userName,
						password: password,
					});
					return res.data;
				})
				.catch((error) => {
					if (
						error.code === 'ERR_NETWORK' ||
						error.code === 'ETIMEDOUT' ||
						error.code === 'ECONNABORTED'
					) {
						commit(types.LOGIN, {
							centerId: centerId,
							userName: userName,
							password: password,
						});
					} else {
						throw error;
					}
				});
		},
		logout({ state, commit }, { user }) {
			window.axios.get(`/center/${user.centerId}/students/${user.userName}/log-out`).then(() => {
				commit(types.LOGOUT, { user });
			})
		},
	},

	mutations: {
		[types.LOGIN](state, user) {
			console.log('loging in...');

			// chech if the user is loged beffor
			const oldUser = state.users.find((e) => e.userName === user.userName);

			// the user loged beffor
			if (oldUser) {
				// get the index of the user inside users
				const oldUserIndex = state.users.findIndex((e) => e.userName === user.userName);

				// apdate the user info in state.user
				state.users[oldUserIndex] = user;
			} else {
				// add the user
				state.users.push(user);
			}

			// update the active user in localstorage
			localStorage.setItem('active-user', JSON.stringify(user));

			// update the active user in store
			state.activeUser = user;
			// update the center in store
			state.centerId = user.centerId;

			// update the users in localstorage
			localStorage.setItem('auth', JSON.stringify(state.users));

			// add password to axios header
			window.axios.defaults.headers['X-Password'] = user.password;
		},

		[types.LOGOUT](state, user) {
			console.log('loging out...');
			// get the user index in users
			const userIndex = state.users.findIndex((e) => e.userName === user.user.userName);

			// if the user didn't found
			if (userIndex === -1) return;

			// remove the user from store
			state.users.splice(userIndex, 1);

			// update the users in localstorage
			localStorage.setItem('auth', JSON.stringify(state.users));

			const activeUser = JSON.parse(localStorage.getItem('active-user'));

			if (activeUser && activeUser.userName === user.user.userName) {
				localStorage.setItem('active-user', JSON.stringify(null));
			}

			// cheack if ther is no other users
			if (!state.users.length) {
				// reset the active user and centerId
				state.activeUser = null;
				state.centerId = null;

				// remove the active user from localstorage
				localStorage.setItem('active-user', JSON.stringify(null));

				// remove password from axios header
				delete window.axios.defaults.headers['X-Password'];
			}
		},

		offline(state) {
			state.offline = true;
		},
	},
};
