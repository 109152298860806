import HomeView from '../views/HomeView.vue';
import Login from '../views/Login.vue';
import Grades from '../views/Grades/Grades.vue';
import GradesView from '../views/Grades/GradeView.vue';
import Exams from '../views/Exams/Exams.vue';
import ExamProgram from '../views/Exams/ExamProgram.vue';
import Statistics from '../views/Statistics/Statistics.vue';
import ScheduleView from '../views/ScheduleView.vue';
import ChatView from '../views/ChatView.vue';
import BehaviorView from '../views/BehaviorView.vue';
import AttendanceView from '../views/AttendanceView.vue';
import AboutView from '../views/AboutView.vue';
import AccountsView from '../views/AccountsView.vue';
import NotificationsView from '../views/NotificationsView.vue';
import FundsView from '../views/FundsView.vue';
import BusView from '../views/BusView.vue';
import HomeWork from '../views/Homeworks/HomeworkView.vue'

export default [
	{
		path: '/',
		name: 'home',
		component: HomeView,
	},
	{
		path: '/log-in',
		name: 'login',
		component: Login,
	},
	{
		path: '/grades',
		name: 'grades',
		component: Grades,
	},
	{
		path: '/grade-view/:id',
		name: 'grade-view',
		component: GradesView,
	},
	{
		path: '/exams',
		name: 'exams',
		component: Exams,
	},
	{
		path: '/exam-program',
		name: 'exam-program',
		component: ExamProgram,
	},
	{
		path: '/statistics',
		name: 'statistics',
		component: Statistics,
	},
	{
		path: '/schedule',
		name: 'schedule',
		component: ScheduleView,
	},
	{
		path: '/chat',
		name: 'chat',
		component: ChatView,
	},
	{
		path: '/behavior',
		name: 'behavior',
		component: BehaviorView,
	},
	{
		path: '/attendance',
		name: 'attendance',
		component: AttendanceView,
	},
	{
		path: '/about',
		name: 'about',
		component: AboutView,
	},
	{
		path: '/accounts',
		name: 'accounts',
		component: AccountsView,
	},
	{
		path: '/notifications',
		name: 'notifications',
		component: NotificationsView,
	},
	{
		path: '/funds',
		name: 'funds',
		component: FundsView,
	},
	{
		path: '/bus',
		name: 'bus',
		component: BusView,
	},
	{
		path: '/homework',
		name: 'homework',
		component: HomeWork,
	},
	{
		path: '*',
		redirect: '/',
	},
];
