import Vue from 'vue';
import Vuex from 'vuex';
import login from './modules/login';
import exams from './modules/exams';
import home from './modules/home';
import behavior from './modules/behavior';
import messages from './modules/messages';
import marks from './modules/marks';
import statistics from './modules/statistics';
import schedule from './modules/schedule';
import attendance from './modules/attendance';
import funds from './modules/funds';
import notifications from './modules/notifications';
import bus from './modules/bus';
import homeworks from './modules/homeworks';

Vue.use(Vuex);

export default new Vuex.Store({
	actions: {
		clearAll({ dispatch }) {
			dispatch('exams/clear');
			dispatch('home/clear');
			dispatch('marks/clear');
			dispatch('statistics/clear');
			dispatch('attendance/clear');
			dispatch('notifications/clear');
		},
	},
	modules: {
		login,
		exams,
		home,
		behavior,
		messages,
		marks,
		statistics,
		schedule,
		attendance,
		notifications,
		funds,
		bus,
		homeworks
	},
});
