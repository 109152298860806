export function diffDate(first, sec = new Date()) {
	// deffrent time in days between two times where the secound parameter is the previously time
	return Math.floor((first.setHours(0, 0, 0, 0) - sec.setHours(0, 0, 0, 0)) / 86400000);
}

export function dateFormater(date) {
	return new Intl.DateTimeFormat('sp-Br').format(date);
}

export function dateFormaterAndTime(date) {
	return new Intl.DateTimeFormat('pt-BR', {
		dateStyle: 'short',
		timeStyle: 'short',
	}).format(date);
}

export function getDay(date) {
	return new Intl.DateTimeFormat('ar-SY', { weekday: 'long' }).format(date);
}

export function getMonth(date) {
	return new Intl.DateTimeFormat('ar-sy', { month: 'long' }).format(date);
}

export function getTime(date) {
	return new Intl.DateTimeFormat('sp-Br', {
		hour: '2-digit',
		minute: '2-digit',
		hour12: false,
	}).format(date);
}

export function getShortDate(date) {
	return new Intl.DateTimeFormat('ar-SY', {
		month: 'long',
		year: 'numeric',
	}).format(date);
}

export function getMonthAndDate(date) {
	return new Intl.DateTimeFormat('ar-SY', {
		month: 'numeric',
		day: 'numeric',
	}).format(date);
}
