export const fundsType = {
	Paid: 0,
	Due: 1,
	Discount: 2,
	Throwback: 3,
	Rival: 4,
	UnDefined: 5,
};

export const notificationTypes = {
	message: 'رسالة',
	attendance: 'سجل الحضور',
	newMark: 'علامة جديدة',
	newMarks: 'علامات',
	schedule: 'تعديل برنامج الدوام',
	newTest: 'إضافة اختبار جديد',
	testsSchedule: 'تعديل برنامج المذاكرات',
	transport: 'مواصلات',
	notes: 'ملاحظة',
}