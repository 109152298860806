<template>
	<div
		fluid
		class="funds-container">
		<v-simple-table
			dense
			class="transparent">
			<template v-slot:default>
				<thead>
					<tr>
						<th class="text-right">المبلغ<span class="text-caption">(ل.س)</span></th>
						<th class="text-right">التاريخ</th>
						<th class="text-right">التفاصيل</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="item in funds?.AllFunds"
						:key="item.Id">
						<td :style="{ color: color(item.FundStatus) }">
							{{ Intl.NumberFormat().format(item.OutAmount || item.InAmount) }}
						</td>
						<td>{{ dateFormater(new Date(item.DateTime)) }}</td>
						<td>{{ item.Details }}</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>

		<div
			class="totals white pa-3 pb-5 elevation-2"
			v-if="funds">
			<!-- <v-divider class="my-2"></v-divider> -->
			<div class="grid">
				<div></div>
				<div>الكلي<span class="text-caption">(ل.س)</span></div>
				<div>المدفوع<span class="text-caption">(ل.س)</span></div>
				<div>الباقي<span class="text-caption">(ل.س)</span></div>
				<div class="font-weight-bold">التعليم</div>
				<div>
					{{ Intl.NumberFormat().format(funds.TotalEducation) }}
				</div>
				<div>
					{{ Intl.NumberFormat().format(funds.TotalInAmountEducation) }}
				</div>
				<div>
					{{ Intl.NumberFormat().format(funds.TotalEducation - funds.TotalInAmountEducation) }}
				</div>
				<div class="font-weight-bold">المواصلات</div>
				<div>
					{{ Intl.NumberFormat().format(funds.TotalTransport) }}
				</div>
				<div>
					{{ Intl.NumberFormat().format(funds.TotalInAmountTransports) }}
				</div>
				<div>
					{{ Intl.NumberFormat().format(funds.TotalTransport - funds.TotalInAmountTransports) }}
				</div>
				<v-divider class="my-1"></v-divider>
				<div class="font-weight-bold">الكلي</div>
				<div>
					{{ Intl.NumberFormat().format(funds.TotalEducation + funds.TotalTransport) }}
				</div>
				<div>
					{{
						Intl.NumberFormat().format(
							funds.TotalInAmountEducation + funds.TotalInAmountTransports,
						)
					}}
				</div>
				<div>
					{{
						Intl.NumberFormat().format(
							funds.TotalEducation +
								funds.TotalTransport -
								(funds.TotalInAmountEducation + funds.TotalInAmountTransports),
						)
					}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { dateFormater } from '@/helpers/helpers';
import { fundsType } from '@/helpers/enums';
export default {
	name: 'FundsView',

	data() {
		return {
			funds: null,

			sheet: true,

			fundsType,
		};
	},

	methods: {
		dateFormater,
		color(fundsType) {
			switch (fundsType) {
			case this.fundsType.Paid:
				return '#4caf50';
			case this.fundsType.Due:
				return '#ef5350';
			case this.fundsType.Discount:
				return '#f57f17';
			case this.fundsType.Throwback:
				return '#00796b';
			case this.fundsType.Rival:
				return '#1e88e5';
			case this.fundsType.UnDefined:
				return '#000';
			}
		},
		fetch() {
			this.$eventBus.$emit('page-loader', true);
			this.$store
				.dispatch('funds/fetchFunds')
				.then((res) => {
					this.funds = res;
				})
				.finally(() => {
					this.$eventBus.$emit('page-loader', false);
				});
		},
	},

	mounted() {
		this.fetch();
	},
};
</script>

<style lang="scss" scoped>
.grid {
	display: grid;
	grid-template-columns: repeat(4, auto);
	gap: 5px;
	hr {
		grid-column-start: 1;
		grid-column-end: 5;
	}
}
.funds-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.totals {
	position: sticky;
	bottom: 0;
}
</style>
