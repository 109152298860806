import app, { publicKey } from './app'
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';
import { getNotificationOptions, notificationTypePage, onMessageHandler } from '@/firebase/notification-utils';
import router from '@/router';

let messaging = {};
async function getDeviceToken() {
	let deviceToken = null;

	const supported = await isSupported();
	if (supported) {
		const userRes = await Notification.requestPermission();
		if (userRes === 'granted') {
			messaging = getMessaging(app);

			const registration = await navigator.serviceWorker.ready;

			await getToken(messaging, { vapidKey: publicKey, serviceWorkerRegistration: registration })
				.then((currentToken) => {
					if (currentToken) {
						deviceToken = currentToken;
					} else {
						console.log('No registration token available. Request permission to generate one.');
						alert('Please, make sure you gave the app notification permission then reload.')
					}
				}).catch((err) => {
					console.log('An error occurred while retrieving token. \n', err);
					alert('Please, make sure you gave the app notification permission then reload.');
				});

			onMessage(messaging, (payload) => {
				const title = payload.notification.title;
				const body = payload.notification.body;
                
				const notification = new Notification(title, getNotificationOptions(body));
				notification.onclick = () => {
					const page = notificationTypePage(payload.notification.title);
					if (page.includes('/')) {
						window.open(page, '_self');
					} else {
						router.push({ name: page });
					}
				}

				onMessageHandler();
			});
		}
	}

	return deviceToken;
}

export { getDeviceToken, messaging, isSupported as isNotificationsSupported };