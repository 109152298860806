// import * as types from '../mutations';

export default {
	namespaced: true,

	state: {},

	actions: {
		fetchBusInfo({ rootState }) {
			return window.axios
				.get(
					`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/get-bus`,
				)
				.then((res) => {
					return res.data;
				});
		},

		fetchPoints({ rootState }) {
			return window.axios
				.get(
					`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/get-points`,
				)
				.then((res) => {
					return res.data;
				});
		},

		fetchStatus({ rootState }) {
			return window.axios
				.get(
					`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/get-status`,
				)
				.then((res) => {
					return res.data;
				});
		},
	},
};
