export default {
	exams: {
		name: 'exams',
		keyPath: 'SubjectId',
	},
	examSchedule: {
		name: 'exam-schedule',
		keyPath: 'Id',
	},
	studentRank: {
		name: 'student-rank',
		keyPath: 'Title',
	},
	marks: {
		name: 'marks',
		keyPath: 'SubjectId',
	},
	schedule: {
		name: 'schedule',
		keyPath: 'Id',
	},
	messages: {
		name: 'messages',
		keyPath: 'ID',
	},
	notifications: {
		name: 'notifications',
		keyPath: 'Id',
	},
};
