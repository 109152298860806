<template>
	<div>
		<chat></chat>
	</div>
</template>

<script>
import Chat from '@/components/Chat.vue';
export default {
	name: 'CharView',
	components: {
		Chat,
	},
};
</script>
