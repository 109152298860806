<template>
	<div
		class="d-flex justify-center align-center py-16"
		v-if="loading">
		<v-progress-circular
			indeterminate
			color="primary"></v-progress-circular>
	</div>
	<div
		v-else
		class="messages d-flex flex-column-reverse align-center">
		<div style="width: 100%">
			<div class="d-flex flex-column">
				<div
					dense
					color="transparent"
					class="d-flex flex-column">
					<div
						v-for="(message, i) in messages"
						:key="i"
						class="px-0 mb-2">
						<message :message="message"></message>
					</div>
				</div>
				<div
					height="150"
					class="px-2 white text-area message-input">
					<p
						v-if="$isOffline.value"
						class="white darken-1 font-weight-bold mb-0 pa-2 text--secondary">
						لا يمكن ارسال بدون اتصال بالانترنت
					</p>
					<div
						class="d-flex pt-2"
						style="width: 100%">
						<div class="ml-2 flex-grow-1">
							<v-textarea
								v-model="message"
								label="الرسالة"
								outlined
								dense
								hide-details
								:rows="2"
								class="rounded-lg"></v-textarea>
							<v-checkbox
								:disabled="$isOffline.value"
								dense
								hide-details=""
								label="مرسل من قبل ولي الأمر"
								v-model="isParent"></v-checkbox>
						</div>
						<div>
							<v-btn
								:disabled="$isOffline.value"
								@click="sendMessage"
								class="py-4"
								color="primary">
								<v-icon class="send-icon text-h5"> mdi-send-variant </v-icon>
							</v-btn>
						</div>
					</div>

					<p class="mb-2 mt-3 px-2">يتم الرد على الرسائل خلال يومي عمل ضمن أوقات الدوام الرسمي</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Message from '@/components/Message.vue';
import { mapState } from 'vuex';

export default {
	name: 'Chat',
	components: { Message },
	data() {
		return {
			message: null,
			isParent: false,

			sent: false,
			loading: true,
		};
	},
	computed: {
		...mapState({
			messages: (state) => state.messages.messages,
		}),
	},
	methods: {
		sendMessage() {
			this.$store
				.dispatch('messages/sendMessage', {
					message: this.message,
					isParent: this.isParent,
				})
				.then(() => {
					this.$eventBus.$emit('show-snackbar', false, 'تم إرسال رسالتك');
					this.sent = true;
					this.message = null;
					this.fetch();
				});
		},
		fetch() {
			this.loading = true;
			this.$store.dispatch('messages/fetchAll').finally(() => {
				this.loading = false;
			});
		},
	},
	mounted() {
		this.fetch();
	},
};
</script>

<style lang="scss" scoped>
.send-icon {
	transform: scale(-1);
}
.messages {
	padding-top: 10px;
	height: calc(100vh - 60px);
	overflow-y: scroll;
}
.message-input {
	position: sticky;
	bottom: 0;
}
</style>
