<template>
	<div class="page">
		<v-row>
			<v-col
				cols="12"
				md="6">
				<!-- next exam -->
				<v-card
					v-if="lastExam && diffDate(new Date(lastExam.Date)) >= 0"
					class="big-card px-4 py-3 mb-4"
					rounded="xl"
					elevation="3">
					<div class="d-flex white--text justify-space-between align-center">
						<div>
							<div
								class="px-2 rounded-xl white primary--text mb-1 text-caption"
								style="width: max-content">
								المذاكرة القدامة
							</div>
							<div class="text-h4">
								{{ lastExam.SubjectName }}
							</div>
							<div class="mb-2">
								{{ lastExam.Details }}
							</div>

							<!-- exam date -->
							<div class="d-flex text-caption">
								<div class="ml-2">
									<v-icon
										color="white"
										small>
										mdi-calendar-blank-outline
									</v-icon>
									{{ dateFormater(new Date(lastExam.Date)) }}
								</div>
								<div>
									<v-icon
										color="white"
										small>
										mdi-clock-outline
									</v-icon>
									{{ getTime(new Date(lastExam.StartTime)) }}
									-
									{{ getTime(new Date(lastExam.EndTime)) }}
								</div>
							</div>
						</div>

						<!-- days diff -->
						<div class="text-center pr-1">
							<template>
								<div class="text-h5">
									{{ diffDate(new Date(lastExam.Date)) }}
								</div>
								<div class="text">أيام متبقية</div>
							</template>
							<!-- <template v-if="diffDate(new Date(lastExam.Date))">
								<div class="text-h5 ml-3">اليوم</div>
							</template> -->
						</div>
					</div>
				</v-card>

				<!-- exam program button -->
				<v-btn
					color="primary"
					rounded
					block
					class="font-weight-bold"
					:to="{ name: 'exam-program' }">
					عرض برنامج المذاكرات
				</v-btn>
			</v-col>
			<v-col
				cols="12"
				md="6">
				<!-- exams list -->
				<v-expansion-panels flat>
					<v-expansion-panel
						v-for="(exam, i) in exams"
						:key="i"
						class="mb-3 rounded-xl elevation-1">
						<!-- exam item header -->
						<v-expansion-panel-header v-slot="{ open }">
							<v-row
								no-gutters
								align="center">
								<v-col cols="4">
									<!-- exam subject -->
									<div class="text-h6 d-inline-block">
										{{ exam.SubjectName }}
									</div>

									<!-- last exam description -->
									<div
										class="text-caption"
										v-show="!open">
										{{ exam.subjectTests[0].Details }}
									</div>
								</v-col>

								<!-- last exam date -->
								<v-col
									v-if="!open"
									cols="4"
									class="text-caption text-center">
									{{ dateFormater(new Date(exam.subjectTests[0].Date)) }}
								</v-col>

								<!-- last exam statue -->
								<v-col
									v-if="!open"
									cols="4"
									class="text-caption text-center"
									style="width: max-content">
									<v-icon
										class="mx-n2"
										:color="exam.subjectTests[0].HasMark ? 'myGreen' : 'myOrange'">
										mdi-circle-medium
									</v-icon>
									{{ exam.subjectTests[0].HasMark ? 'صدرت' : 'لم تصدر' }}
								</v-col>

								<!-- button to subject grades -->
								<v-col
									v-if="open"
									cols="auto">
									<router-link
										:to="{
											name: 'grade-view',
											params: {
												id: exam.subjectTests[0].SubjectId,
											},
										}"
										v-if="open"
										class="ms-4 px-3 py-1 text-caption secondary white--text rounded-xl d-inline-block">
										عرض العلامة
									</router-link>
								</v-col>
							</v-row>
						</v-expansion-panel-header>

						<!-- exam item body aka more exams -->
						<v-expansion-panel-content class="px-1">
							<v-divider class="mb-3"></v-divider>
							<v-list dense>
								<!-- more exams list -->
								<v-list-item
									dense
									v-for="(item, i) in exam.subjectTests"
									:key="i">
									<v-row no-gutters>
										<!-- date -->
										<v-col
											cols="3"
											class="text-body-2">
											{{ dateFormater(new Date(item.Date)) }}
										</v-col>

										<!-- descrition -->
										<v-col
											cols="6"
											class="text-body-2">
											{{ item.Details }}
										</v-col>

										<!-- status -->
										<v-col
											cols="3"
											class="text-body-2 ps-2">
											<div style="width: max-content">
												<v-icon
													class="mx-n2"
													:color="item.HasMark ? 'myGreen' : 'myOrange'">
													mdi-circle-medium
												</v-icon>
												{{ item.HasMark ? 'صدرت' : 'لم تصدر' }}
											</div>
										</v-col>
									</v-row>
								</v-list-item>
							</v-list>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { diffDate, dateFormater, getTime } from '@/helpers/helpers';
import { mapState } from 'vuex';
export default {
	name: 'Exams',

	computed: {
		...mapState({
			exams: (state) => state.exams.exams,
			lastExam: (state) => state.exams.lastExam,
		}),
	},

	methods: {
		diffDate,
		dateFormater,
		getTime,
		fetch() {
			this.$eventBus.$emit('page-loader', true);
			Promise.all([
				this.$store.dispatch('exams/fetchAll'),
				this.$store.dispatch('exams/fetchLast'),
			]).finally(() => {
				this.$eventBus.$emit('page-loader', false);
			});
		},
	},

	mounted() {
		this.fetch();
	},
};
</script>

<style lang="scss" scoped>
.big-card {
	background-image: linear-gradient(70deg, var(--v-secondary-base) -10%, var(--v-primary-base) 70%);
}
</style>
